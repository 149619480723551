<script>
import WarehousesForm from './WarehousesForm'

export default {
  extends: WarehousesForm,

  created() {
    this.getWarehouse(this.$route.params.id).then(response => {
      this.form = response
      this.coordinates = response.lon + ', ' + response.lat
    })
  },

  methods: {
    onSubmit() {
      this.updateWarehouse(this.form.id, { ...this.form, ...this.getCoords })
        .then(() => {
          this.successSubmit({ message: 'Склад успешно изменен', routeName: 'warehouses' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
